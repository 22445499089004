import StatusLabel from '@/common/components/StatusLabel';
import { FC } from 'react';
import { useRequestStatus } from '../hooks/useRequestStatus';
import { RequestStatus } from '../types/request';

type RequestStatusLabelProps = {
  status: RequestStatus;
};

const RequestStatusLabel: FC<RequestStatusLabelProps> = (props: RequestStatusLabelProps) => {
  const { status } = props;

  const { getStatusAppearanceInfo } = useRequestStatus();

  const appearanceInfo = getStatusAppearanceInfo(status);

  return <StatusLabel status={appearanceInfo} testId='request-status-label' />;
};

export default RequestStatusLabel;
