import useTranslation from '@/utils/i18n/useTranslation';
import { useCallback } from 'react';
import { IconType } from 'react-icons';
import { FaArrowAltCircleRight, FaCheckCircle } from 'react-icons/fa';
import { FaCircleStop, FaCircleXmark } from 'react-icons/fa6';
import { RequestStatus } from '../types/request';

type ReturnType = {
  getStatusAppearanceInfo(value: RequestStatus): RequestStatusAppearanceInfo;
  getStatusLabel(value: RequestStatus): string;
};

export type RequestStatusAppearanceInfo = {
  value: RequestStatus;
  label: string;
  color: string;
  icon: IconType;
  defaultLabelKey: RequestStatus;
};

export const pendingStatus: RequestStatusAppearanceInfo = {
  value: 'pending',
  label: '依頼中',
  color: 'blue.600',
  icon: FaArrowAltCircleRight,
  defaultLabelKey: 'pending',
};

const approvedStatus: RequestStatusAppearanceInfo = {
  value: 'approved',
  label: '承認済',
  color: 'success',
  icon: FaCheckCircle,
  defaultLabelKey: 'approved',
};

const rejectedStatus: RequestStatusAppearanceInfo = {
  value: 'rejected',
  label: '却下',
  color: 'warning.500',
  icon: FaCircleXmark,
  defaultLabelKey: 'rejected',
};

export const canceledStatus: RequestStatusAppearanceInfo = {
  value: 'canceled',
  label: '取り下げ',
  color: 'neutral.500',
  icon: FaCircleStop,
  defaultLabelKey: 'canceled',
};

export const allStatusLabelOptions = [
  pendingStatus,
  approvedStatus,
  rejectedStatus,
  canceledStatus,
];

export const statusLabelMap = new Map<RequestStatus, RequestStatusAppearanceInfo>(
  allStatusLabelOptions.map((option) => [option.value, option])
);

export const useRequestStatus = (): ReturnType => {
  const { t } = useTranslation();

  const getStatus = useCallback((value: RequestStatus) => {
    const status = statusLabelMap.get(value);

    if (!status) throw new Error(`Invalid status value: ${value}`);

    return status;
  }, []);

  const getStatusLabel = useCallback(
    (value: RequestStatus) => {
      const { defaultLabelKey, label } = getStatus(value);

      return t(`status.request.${defaultLabelKey}`, label);
    },

    [getStatus, t]
  );

  const getStatusAppearanceInfo = useCallback(
    (value: RequestStatus) => {
      const status = getStatus(value);
      const label = getStatusLabel(value);

      return { ...status, label };
    },
    [getStatus, getStatusLabel]
  );

  return {
    getStatusLabel,
    getStatusAppearanceInfo,
  };
};
