import WorkOrderPriorityLabel from '@/components/workOrders/WorkOrderPriorityLabel';
import { IDisplayUser } from '@/modules/users';
import WorkOrderStatusLabel from '@/modules/workOrders/components/WorkOrderStatusLabel';
import { type IDisplayWorkOrder, WorkOrderAllStatuses } from '@/modules/workOrders/types/workOrder';
import { formatDateToMDHHmm_or_YYYYMDHHmm } from '@/utils/date/date';
import { Avatar, Box, Flex, Icon, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { FC } from 'react';
import { FaClipboard } from 'react-icons/fa';
import { RequestPriority, RequestStatus } from '../types/request';
import RequestStatusLabel from './RequestStatusLabel';

export type RequestCardProps = {
  id: number;
  title: string;
  status: RequestStatus;
  priority: RequestPriority;
  workOrders?: IDisplayWorkOrder[];
  createdAt: Date;
  createdBy?: IDisplayUser;
  onCardClicked: (id: number) => void;
  isActive?: boolean;
};

const RequestCard: FC<RequestCardProps> = (props: RequestCardProps) => {
  const {
    id,
    title,
    status,
    priority,
    workOrders = [],
    createdAt,
    createdBy,
    onCardClicked,
    isActive,
  } = props;

  const showWorkOrderStatuses = status === 'approved' && workOrders.length > 0;

  const workOrderStatusesToShow = showWorkOrderStatuses
    ? WorkOrderAllStatuses.filter((status) => workOrders.some((wo) => wo.status === status))
    : [];

  return (
    <Box
      p={2}
      _hover={{
        bg: isActive ? 'primary.25' : 'neutral.50',
        cursor: 'pointer',
      }}
      bg={isActive ? 'primary.25' : 'transparent'}
      borderWidth={1}
      onClick={() => onCardClicked(id)}
    >
      <Stack align='stretch' textAlign='left'>
        <Flex justifyContent='end' flexWrap='wrap'>
          <Text fontSize='lg' letterSpacing='wide' color='neutral.800'>
            {title}
          </Text>
          <Spacer />
        </Flex>
        <Flex justifyContent='end' flexWrap='wrap'>
          <RequestStatusLabel status={status} />

          {workOrderStatusesToShow.length > 0 && (
            <>
              <Text>&#62;</Text>
              {workOrderStatusesToShow.map((status) => (
                <WorkOrderStatusLabel key={status} status={status} minWidth='0' />
              ))}
            </>
          )}
        </Flex>
        <Flex my={1} justifyContent='space-between'>
          <Flex alignItems='center' flex='1 1 0%' flexWrap='wrap' columnGap={3}>
            {priority && <WorkOrderPriorityLabel priority={priority} />}
            <Flex alignItems='center'>
              <Icon as={FaClipboard} color='neutral.500' fontSize='sm' />
              <Text color='neutral.500' fontSize='sm' ml={1}>
                {formatDateToMDHHmm_or_YYYYMDHHmm(createdAt)}
              </Text>
            </Flex>
          </Flex>
          {createdBy && (
            <Tooltip hasArrow label={createdBy.name}>
              <Avatar size='xs' name={createdBy.name} />
            </Tooltip>
          )}
        </Flex>
      </Stack>
    </Box>
  );
};

export default RequestCard;
